import moment from 'moment';
import store from '@/state/store.js';
export default {
  name: 'admin-users',
  data() {
    return {
      loading: false,
      page: 1,
      pageSize: 50,
      total: 0,
      tableData: []
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    }
  },
  computed: {
    singleIndustry() {
      return this.tableData.individual.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    }
  },
  beforeMount() {
    this.loading = true;
    store.dispatch('admin/reporting/getIndustryReporting').then(res => {
      this.tableData = store.getters['admin/reporting/singleIndustry'](this.$route.params.id);
      this.total = this.tableData.individual.length;
    }).finally(() => this.loading = false);
  }
};